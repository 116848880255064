.App {
  text-align: center;
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-logo {
  height: 80px;
  margin-bottom: 20px;
}

.App-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
}

.App-description {
  font-size: 1.2rem;
  margin-bottom: 30px;
  max-width: 500px;
  text-align: center;
}

.App-header {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-button {
  background-color: #ff813f;
  border: none;
  border-radius: 5px;
  color: white;
  padding: 10px 20px;
  text-transform: uppercase;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.App-button:hover {
  background-color: #ff9a66;
}
